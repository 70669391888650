type rankRenderType = {
  [key: string]: {
    title: string,
    cashback: number,
    insurance: number,
    discount: number,
    toCashback: number,
  }
}

export const rankRender: rankRenderType = {
  bronze: {
    title: 'rank.bronze',
    cashback: 1,
    insurance: 0.75,
    discount: 25,
    toCashback: 5,
  },
  silver: {
    title: 'rank.silver',
    cashback: 2,
    insurance: 0.7,
    discount: 25,
    toCashback: 6,
  },
  gold: {
    title: 'rank.gold',
    cashback: 3,
    insurance: 0.65,
    discount: 25,
    toCashback: 7,
  },
  platinum: {
    title: 'rank.platinum',
    cashback: 5,
    insurance: 0.6,
    discount: 25,
    toCashback: 10,
  },
  vip: {
    title: 'rank.vip',
    cashback: 10,
    insurance: 0.55,
    discount: 25,
    toCashback: 20,
  },
};

export const MYBONUSES_QUESTIONS = [
  {
    id: 1,
    title: 'Что такое VIP-клуб?',
    desc: 'Это программа лояльности, которая даёт возможность экономить Вашей компании на отправке документов и грузов, а администратору (владельцу) личного кабинета получать максимум от доставки с Белкой. В VIP-клубе мы дарим бонусы за каждое доставленное отправление. Бонусы можно обменять на товары и услуги Белки. Кроме того, участники клуба получают персональные промокоды, купоны и спецпредложения. Чем выше уровень, тем больше сумма бонуса.'
  },
  {
    id: 2,
    title: 'За что начисляются бонусы?',
    desc: 'Вы получаете бонусы за каждое доставленное отправление. Дополнительные бонусы можно получить в подарок во время акций.'
  },
  {
    id: 3,
    title: 'Когда начисляются бонусы?',
    desc: 'Раз в месяц до 10 числа включительно, начисляем за доставленные заказы в прошлом месяце.'
  },
  {
    id: 4,
    title: 'Во сколько Белка оценивает один бонус?',
    desc: '1 бонус равен 1 рублю.'
  },
  {
    id: 5,
    title: 'Сколько бонусов я могу получить за одно доставленное отправление?',
    desc: 'Количество начисляемых бонусов зависит от выбранной услуги и текущего уровня в программе лояльности.<br/>Бронза — до 5% бонусами от каждого доставленного отправления.<br/>Серебро — до 6% бонусами от каждого доставленного отправления.<br/>Золото — до 7% бонусами от каждого доставленного отправления.<br/>Платина — до 10% бонусами от каждого доставленного отправления.<br/>VIP – до 20% бонусами от каждого доставленного отправления.'
  },
  {
    id: 6,
    title: 'На что я могу обменять свои бонусы?',
    desc: 'На твоё усмотрение: обменяй бонусы в магазине на подарки от Белки и получи хорошее настроение для продуктивного рабочего дня. Или - трать на услуги — доставку, упаковку и не только. Бонусами можно снизить стоимость услуг до 50%. 1 бонус — 1 рубль.'
  },
  {
    id: 7,
    title: 'Сколько хранятся бонусы?',
    desc: 'Вы можете воспользоваться бонусами в течение 6 месяцев с даты их получения, после этого они сгорают. Бонусы, начисленные в рамках акций и специальных предложений могут иметь другой срок действия: он определяется условиями акций и спецпредложений.'
  },
  {
    id: 8,
    title: 'Где я могу обменять бонусы на услуги или товары?',
    desc: 'Обменять бонусы на услуги можно при формировании заказа в корзине. Обменять бонусы на товары можно в магазине.'
  },
  {
    id: 9,
    title: 'У меня много бонусов на счёте, но стоимость отправления погашается ими не вся. Почему?',
    desc: 'Оплатить бонусами можно не более 50% от стоимости тарифа на перевозку отправления.'
  },
  {
    id: 10,
    title: 'Что произойдёт с начислением бонусов и уже моими списанными бонусами, если после взвешивания у перевозчика вес и стоимость доставки будут другими?',
    desc: 'Если при взвешивании на складе перевозчика фактическая стоимость отправления изменится в большую или меньшую стоимость, на списание или начисление бонусов это никак не повлияет. Пожалуйста, указывайте максимально реальный вес и габариты, при отправке груза.'
  },
  {
    id: 11,
    title: 'Что случится, если я отменю отправление с частичной оплатой бонусами?',
    desc: 'При отмене заказа, ещё не принятого отправления у перевозчика, бонусы вернутся на ваш баланс.'
  }
]