import React, { Suspense } from 'react';
import Row from 'antd/es/grid/row';
import Bonus from './Bonus';
import User from './User';
import useSelector from '../../util/hooks/useSelector';
import useDispatch from '../../util/hooks/useDispatch';
import LogoIcon from '../../assets/navbar/logo_winter.png';

import { Link } from 'react-router-dom';
import CartIndicator from '../../app/cart/components/CartIndicator';
import classNames from 'classnames';
import { Switch } from 'antd';
import { updateUserUI } from '../../app/user/action-creators';
import { AccountGroup } from '../../app/user/types';
import { TopBarSales } from '../icons/TopBarSales';
import { TopBarSupport } from '../icons/TopBarSupport';
import { TopBarOrders } from '../icons/TopBarOrders';

function TopBar() {
  const dispatch = useDispatch();
  const { user, decodedToken, userAccountList } = useSelector((state) => state.user);
  const onlineShop = decodedToken?.group === AccountGroup.SHOP || decodedToken?.group === AccountGroup.CARRIER;

  const isUserOwnerOfCurrentAccount = React.useMemo(() => {
    if (user && userAccountList) {
      const accountFromList = userAccountList.find(i => i.accountId === user.accountId);
      return accountFromList?.ownerId === user.userId;
    }
    return false;
  }, [user, userAccountList])

  return (
    <Row className={classNames("gx-w-100 gx-align-items-center custom-navbar gx-ml-auto gx-mr-auto is-container-slim is-winter")}>
      <div className="gx-d-flex gx-align-items-center gx-mr-auto">
        <Link to="/" className='gx-d-flex gx-align-items-center gx-justify-content-center custom-navbar__logo is-winter'>
          <img src={LogoIcon} alt="logo"/>
        </Link>
        {
          window.location.pathname !== '/'
          ?
          <div className="gx-d-flex gx-align-items-center">
            <Link to="/" className="custom-navbar__global-link">
              По России и ЕАЭС
            </Link>
            <Link to="/" className="custom-navbar__global-link">
              Международная доставка
            </Link>
            {
              onlineShop
              &&
              <div className="custom-navbar__global-link">
                Интернет магазины
              </div>
              ||
              null
            }
          </div>
          :
          null
        }
      </div>
      <div className="gx-d-flex gx-align-items-center gx-ml-auto">
        <div className="gx-d-flex gx-align-items-center">
          {/* <div className="gx-d-flex gx-align-items-center custom-navbar__menu-switch" title='новый личный кабинет'>
            <Switch 
                checked={user?.isUpdatedDesign} 
                size="small"
                onChange={(checked: boolean) => {
                  dispatch(updateUserUI(checked))
                }} />
            { window.location.pathname !== "/" ? null : <div className="custom-navbar__label">Новый кабинет</div> }
          </div> */}
          <div title="Эксклюзивные скидки" className="gx-d-flex gx-align-items-center gx-position-relative custom-navbar__menu-item gx-text-nowrap is-soon">
            <TopBarSales/>
            {window.location.pathname !== '/' ? null : 'Эксклюзивные скидки'}
            <div className={classNames("gx-position-absolute custom-navbar__soon", { "is-only-icon": window.location.pathname !== '/' })}>
              Скоро
            </div>
          </div>
          <Link to="/appeal" title="Служба поддержки" className="gx-d-flex gx-align-items-center gx-position-relative custom-navbar__menu-item gx-text-nowrap">
            <TopBarSupport/>
            {window.location.pathname !== '/' ? null : 'Служба поддержки'}
          </Link>
          <Link to="/orders" title="Мои заказы" className="gx-d-flex gx-align-items-center gx-position-relative custom-navbar__menu-item gx-text-nowrap">
            <TopBarOrders/>
            {window.location.pathname !== '/' ? null : 'Мои заказы'}
          </Link>
          <Suspense fallback={null}>
            <CartIndicator isGlobalMenuShown={window.location.pathname !== '/'}/>
          </Suspense>
        </div>
        {
          isUserOwnerOfCurrentAccount
          &&
          <Suspense fallback={null}>
            <Bonus/>
          </Suspense>
          ||
          null
        }

        <Suspense fallback={null}>
          <User />
        </Suspense>
      </div>
    </Row>
  );
}

export default TopBar;
