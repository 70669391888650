export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CENT = 'SET_CENT';
export const SET_SHOW_POST_REGISTRATION_MODAL = 'SET_SHOW_POST_REGISTRATION_MODAL';
export const SET_USER_INVITE_LIST = 'SET_USER_INVITE_LIST';
export const SET_USER_INVITE_COUNT = 'SET_USER_INVITE_COUNT';
export const RESET_USER_INVITES = 'RESET_USER_INVITES';
export const SET_USER_BALANCE = 'SET_USER_BALANCE';
export const SET_USER_ACCOUNTS = 'SET_USER_ACCOUNTS';
export const SET_NEW_ACCOUNT = 'SET_NEW_ACCOUNT';
export const SET_ACCOUNT_NOTIFICATION_PREFERENCES = 'SET_ACCOUNT_NOTIFICATION_PREFERENCES';
export const SET_USER_UI = 'SET_USER_UI';

export enum AccountRank {
  _ = '_',
  BRONZE = 'bronze',
  SILVER = 'silver',
  GOLD = 'gold',
  PLATINUM = 'platinum',
  VIP = 'vip'
}

export enum AccountGroup {
  PUBLIC = 'public',
  CARRIER = 'carrier',
  SHOP = 'shop'
}

export type UserEmailNotificationPreferences = {
  invoice: boolean,
  chatMessage: boolean,
  waybillDelivered: boolean,
  participantChatMessage: boolean,
};

export interface User {
  userId: number,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  accountId?: number,
  accountTitle?: string,
  accountStatus: number,
  emailVerified: boolean,
  termsAccepted: boolean,
  avatar: string,
  contractId?: number | null,
  language: string,
  allowCp?: boolean,
  rank: AccountRank,
  notificationPreferences: UserEmailNotificationPreferences,
  isUpdatedDesign?: boolean,
  accountLoyalty?: number,
}

export type UserAccount = {
  accountId: number,
  title: string,
  sellerId: number,
  ownerId: number,
};

export interface Token {
  accessToken: string | null,
  refreshToken: string | null | undefined,
  expiredAt: number | undefined
}

export interface ShortUserInfo {
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  avatar: string | null | undefined,
}

export interface UserInvite {
  firstName: string,
  lastName: string,
  inviteMethod: string,
  status: 'pending' | 'accepted',
  id: number,
  avatar: string,
  createdAt: string,
  accountOwner: boolean,
  permissions?: string[],
  membershipId: number,
  newOwner: boolean,
}

export enum PermissionChoices {
  ADMIN = 'admin'
}

export enum AccountLoyaltyChoices {
  RANK = 1,
  DISCOUNT,
  PERSONAL,
  TENDER
}

export type DecodedToken = {
  token_type: string,
  exp: number,
  jti: string,
  user_id: number,
  account_id: number,
  account_owner_id: number,
  sub: number,
  modules: string[],
  is_admin?: boolean,
  account_status: number,
  permissions: PermissionChoices[],
  loyalty: AccountLoyaltyChoices,
  group?: AccountGroup,
};

export enum NotificationPreference {
  INVOICE = 'INVOICE'
}

export type AccountNotificationPreferenceItem = {
  name?: string,
  position?: string,
  phone?: string,
  email: string,
  id: number
};

export type AccountNotificationPreference = {
  preference: NotificationPreference;
  // receivers: string[],
  items: AccountNotificationPreferenceItem[]
};

export interface SetUser {
  type: typeof SET_USER,
  user: User | null
}

export interface SetToken {
  type: typeof SET_TOKEN,
  accessToken: string | null,
  refreshToken?: string | null,
  expiredAt?: number,
  remember?: boolean
}

export interface UserBalance {
  balance: number,
  bonusBalance: number
  bonusHoldBalance: number,
}

export interface SetCent {
  type: typeof SET_CENT,
  cent: any
}

export interface SetShowPostRegistrationModal {
  type: typeof SET_SHOW_POST_REGISTRATION_MODAL,
  show: boolean
}

export interface SetUserInviteList {
  type: typeof SET_USER_INVITE_LIST,
  invites: Array<UserInvite>
}

export interface SetUserInviteCount {
  type: typeof SET_USER_INVITE_COUNT,
  count: number
}

export interface ResetUserInvites {
  type: typeof RESET_USER_INVITES
}

export interface SetUserBalance {
  type: typeof SET_USER_BALANCE,
  balance: UserBalance
}

export interface SetUserAccounts {
  type: typeof SET_USER_ACCOUNTS,
  list: Array<UserAccount>
}

export interface SetNewAccount {
  type: typeof SET_NEW_ACCOUNT,
  new: boolean
}

export interface SetAccountNotificationPreferences {
  type: typeof SET_ACCOUNT_NOTIFICATION_PREFERENCES,
  preferences: AccountNotificationPreference[]
}

export interface SetUserUI {
  type: typeof SET_USER_UI,
  payload: boolean
}

export type UserActions =
  SetUser
  | SetToken
  | SetCent
  | SetShowPostRegistrationModal
  | SetUserInviteList
  | SetUserInviteCount
  | ResetUserInvites
  | SetUserBalance
  | SetUserAccounts
  | SetNewAccount
  | SetAccountNotificationPreferences
  | SetUserUI;
