type SortArrayOfObjectConfig = {
  reversed?: boolean,
  date?: boolean
};

function sortArrayOfObject<T extends anyObject>(arr: Array<T>, key: string, config?: SortArrayOfObjectConfig): Array<T> {
  const tmp = arr.slice();
  if (config?.date) {
    tmp.sort((a, b) =>
      // @ts-ignore
      new Date(b[key]) - new Date(a[key]));
  } else {
    tmp.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }
  if (config?.reversed) tmp.reverse();
  return tmp;
}

export { sortArrayOfObject };
