import moment from 'moment';
import {
  createCargo,
  createFastCargo,
  createReturnCargo,
  resetCargo,
  resetFastCargo,
  setCargoId,
  setPackages,
  updateCurrentCargo,
} from '../cargo/action-creators';
import {
  contactCastComplexCreate,
  createAddressCast,
  createFastAddressCast,
  getFullContactInfoDetail,
  getPostalCodeFromCoords,
} from '../contact/action-creators';
import { ThunkAction } from '../../util/types';
import {
  CalculationSideCast,
  CHECK_CURRENT_FILTERS,
  CheckCurrentFilters,
  FastCalcInitialState,
  SET_AMO_ID,
  SET_CALCULATION_DONE,
  SET_SHARE_EMAIL,
  SetAmoId,
  SetShareEmail,
  SingleRate,
  StartFastCalcResponse,
  UPDATE_SERVICES_FILTER,
  UpdateServicesFilter,
  UPDATE_CURRENT_DELIVERY_WAY_FILTER,
  SetCurrentRate,
  SET_CURRENT_RATE,
  SetRecalculationResults,
  SET_RECALCULATION_DONE,
  SetRecalculationDone,
  SET_RECALCULATION_RUNNING,
  SetRecalculationRunning,
  SET_RECALCULATION_TASK_ID,
  SetRecalculationTaskId,
  SET_RECALCULATION_RESULTS,
  AddRecalculationResults,
  ADD_RECALCULATION_RESULTS,
  RESET_BASE_CALCULATION,
} from './types';
import { Waybill, WaybillDeliveryType } from '../shipping/types';
import { AdditionalServiceCode } from '../cargo/types';
import { executeAll, ResolvedPromise } from '../../util/promise';
import { ContactCast } from '../cast/types';
import { datadaInnAutocomplete, geoSuggest } from '../external/action-creators';
import { adaptToApi } from '../../util/adapter';
import { ymaps } from '../../geo/yandex/Provider';

export const setSenderContact = (contact: any) => ({
  type: 'SET_SENDER_CONTACT',
  contact,
});

export const setReceiverContact = (contact: any) => ({
  type: 'SET_RECEIVER_CONTACT',
  contact,
});

export const setCalculationPollingTimer = (pollingTimer: any) => ({
  type: 'SET_CALCULATION_POLLING_TIMER',
  pollingTimer,
});

export const setAmoId = (amoId: null | string | number): SetAmoId => ({
  type: SET_AMO_ID,
  amoId,
});

export const setShareEmail = (shareEmail: null | string): SetShareEmail => ({
  type: SET_SHARE_EMAIL,
  shareEmail,
})

export const resetCalculation = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch(resetCargo());
  dispatch(setCalculationPollingTimer(clearTimeout(getState().calculation.pollingTimer)));
  dispatch({ type: 'RESET_CALCULATION', isFastOrder: false });
  dispatch(setAmoId(null));
  dispatch(setShareEmail(null));
};

export const resetFastCalculation = (isFastOrder: boolean): ThunkAction<void> => (dispatch, getState) => {
  if (!isFastOrder) {
    dispatch(resetFastCargo());
  }
  dispatch(setCalculationPollingTimer(clearTimeout(getState().calculation.pollingTimer)));
  dispatch({ type: 'RESET_CALCULATION', isFastOrder });
  dispatch(setAmoId(null));
  dispatch(setShareEmail(null));
};

export const resetBaseCalculation = (): ThunkAction<void> => (dispatch, getState) => {
  dispatch({ type: RESET_BASE_CALCULATION });
  dispatch(setCalculationPollingTimer(clearTimeout(getState().calculation.pollingTimer)));
  dispatch(setAmoId(null));
  dispatch(setShareEmail(null));
};

export const setCalculationCargo = (cargo: any) => ({
  type: 'SET_CALCULATION_CARGO',
  cargo,
});

export const setDateFrom = (dateFrom: any) => ({
  type: 'SET_DATE_FROM',
  dateFrom,
});

export const setCalculationRunning = (running: any) => ({
  type: 'SET_CALCULATION_RUNNING',
  running,
});

export const setCalculationTaskId = (taskId: any) => ({
  type: 'SET_CALCULATION_TASK_ID',
  taskId,
});

export const setCalculationResults = (results: any) => ({
  type: 'SET_CALCULATION_RESULTS',
  results,
});

export const addCalculationResults = (results: any) => ({
  type: 'ADD_CALCULATION_RESULTS',
  results,
});

export const setCalculationPreState = (preState: any) => ({
  type: 'SET_CALCULATION_PRE_STATE',
  preState,
});

export const setCalculationSenderAddress = (address: any) => ({
  type: 'SET_SENDER_ADDRESS',
  address,
});

export const setCalculationReceiverAddress = (address: any) => ({
  type: 'SET_RECEIVER_ADDRESS',
  address,
});

export const setCalculationSender = (sender: any) => ({
  type: 'SET_CALCULATION_SENDER',
  sender,
});

export const setCalculationReceiver = (receiver: any) => ({
  type: 'SET_CALCULATION_RECEIVER',
  receiver,
});

export const setCalculationSenderCast = (cast: CalculationSideCast) => ({
  type: 'SET_CALCULATION_SENDER_CAST',
  cast,
});

export const setCalculationReceiverCast = (cast: CalculationSideCast) => ({
  type: 'SET_CALCULATION_RECEIVER_CAST',
  cast,
});

export const updateCalculationSenderCast = (cast: any) => ({
  type: 'UPDATE_CALCULATION_SENDER_CAST',
  cast,
});

export const updateCalculationReceiverCast = (cast: any) => ({
  type: 'UPDATE_CALCULATION_RECEIVER_CAST',
  cast,
});

export const calcAvailableFilters = () => ({
  type: 'CALC_AVAILABLE_FILTERS',
});

export const checkCurrentFilters = (): CheckCurrentFilters => ({
  type: CHECK_CURRENT_FILTERS,
});

export const updateCurrentDeliveryServicesFilter = (deliveryServices: Array<string>) => ({
  type: 'UPDATE_CURRENT_DELIVERY_SERVICE_FILTER',
  deliveryServices,
});

export const updateCurrentDeliveryWayFilter = (deliveryWay: string[]) => ({
  type: UPDATE_CURRENT_DELIVERY_WAY_FILTER,
  deliveryWay,
});

export const setCurrentOrdering = (ordering: 'price' | 'minDays') => ({
  type: 'SET_CURRENT_ORDERING',
  ordering,
});

export const switchCurrentFilters = (filter: 'deliveryServices') => ({
  type: 'SWITCH_CURRENT_FILTER',
  filter,
});

export const setCalculationDone = (done: boolean) => ({
  type: SET_CALCULATION_DONE,
  done,
});

export const updateServicesFilter = (services: string[], initial: boolean = false): UpdateServicesFilter => ({
  type: UPDATE_SERVICES_FILTER,
  services,
  initial,
});

export const setCurrentRate = (payload: string): SetCurrentRate => ({
  type: SET_CURRENT_RATE,
  payload
})

/* Recalculating */
export const setRecalculationResults = (results: any): SetRecalculationResults => ({
  type: SET_RECALCULATION_RESULTS,
  results,
});

export const setRecalculationDone = (done: boolean): SetRecalculationDone => ({
  type: SET_RECALCULATION_DONE,
  done,
});

export const setRecalculationRunning = (running: boolean): SetRecalculationRunning => ({
  type: SET_RECALCULATION_RUNNING,
  running,
});

export const setRecalculationTaskId = (taskId: any): SetRecalculationTaskId => ({
  type: SET_RECALCULATION_TASK_ID,
  taskId,
});

export const addRecalculationResults = (results: any): AddRecalculationResults => ({
  type: ADD_RECALCULATION_RESULTS,
  results,
})

export const acceptWsRecalculation = (singleRateId: any, command: any, data: any): ThunkAction<void> => (dispatch, getState) => {
  const { calculation: { recalcTaskId } } = getState();
  if (recalcTaskId === singleRateId) {
    if (command === 'results') {
      dispatch(addRecalculationResults(data));
    } else if (command === 'done') {
      dispatch(setRecalculationDone(true));
    }
  }
};

/* End Recalculating */


export const acceptWsRate = (singleRateId: any, data: any): ThunkAction<void> => (dispatch, getState) => {
  dispatch(addCalculationResults(data));
};

export const acceptWsCalculation = (singleRateId: any, command: any, data: any): ThunkAction<void> => (dispatch, getState) => {
  const { calculation: { taskId, pollingTimer } } = getState();
  if (taskId === singleRateId) {
    if (command === 'results') {
      dispatch(addCalculationResults(data));
    } else if (command === 'done') {
      clearTimeout(pollingTimer);
      dispatch(setCalculationPollingTimer(null));
      dispatch(setCalculationDone(true));
    }
  }
};

export const calculationResultPolling = (taskId: any, run = 1): ThunkAction<void> => (dispatch, getState, http) => {
  if (taskId !== getState().calculation.taskId) return;
  if (run === 5) return;
  http.get(`/api/v1/calculation/${taskId}/results/`).then(
    (response: any) => {
      if (taskId !== getState().calculation.taskId) return;
      dispatch(setCalculationResults(response.results.map((item: any) => ({...item, singleTaskId: taskId}))));
      dispatch(setCalculationPollingTimer(setTimeout(() => {
        dispatch(calculationResultPolling(taskId, run + 1));
      }, 5000)));
    },
    () => dispatch(setCalculationPollingTimer(setTimeout(() => {
      dispatch(calculationResultPolling(taskId, run + 1));
    }, 5000))),
  );
};

export const startCalculationWithReturn = (): ThunkAction => (dispatch, getState, http) => {
  const { senderCast, receiverCast } = getState().calculation;
  const { additionalServices } = getState().cargo;
  dispatch(setCalculationResults([]));
  dispatch(setCalculationDone(false));
  if (senderCast && receiverCast) {
    const cargoPromises = [
      dispatch(createCargo()),
    ];
    cargoPromises.push(dispatch(createReturnCargo()));

    return Promise.all(executeAll(cargoPromises)).then(
      ([cargoResponse, returnCargoResponse]) => {
        console.debug('then', cargoResponse, returnCargoResponse);
        let errors: anyObject = {};
        let hasError = false;
        let cargoId: null | string = null;
        let returnCargoId: null | string = null;
        if (cargoResponse.status === 'rejected') {
          errors = cargoResponse.errors;
          hasError = true;
        } else {
          cargoId = cargoResponse.response.cargoId;
        }

        if (returnCargoResponse.status === 'rejected') {
          errors.returnPackages = returnCargoResponse.errors.packages;
          hasError = true;
        } else if (returnCargoResponse.response) {
          returnCargoId = returnCargoResponse.response.cargoId;
        }
        if (hasError) return Promise.reject(errors);
        const sidePromises = [];
        if (!senderCast.addressCastId) {
          // @ts-ignore
          sidePromises.push(dispatch(createAddressCast(senderCast)).then(
            (response: any) => {
              dispatch(updateCalculationSenderCast({ addressCastId: response.addressCastId }));
              return Promise.resolve(response.addressCastId);
            },
          ));
        } else {
          sidePromises.push(Promise.resolve(senderCast.addressCastId));
        }
        if (!receiverCast.addressCastId) {
          // @ts-ignore
          sidePromises.push(dispatch(createAddressCast(receiverCast)).then(
            (response: any) => {
              dispatch(updateCalculationReceiverCast({ addressCastId: response.addressCastId }));
              return Promise.resolve(response.addressCastId);
            },
          ));
        } else {
          sidePromises.push(Promise.resolve(receiverCast.addressCastId));
        }
        return Promise.all(sidePromises).then(
          ([senderAddressCastId, receiverAddressCastId]) => {
            const data: anyObject = {
              sender_address_id: senderAddressCastId,
              receiver_address_id: receiverAddressCastId,
              cargo_id: cargoId,
              pickup_date: moment().format('YYYY-MM-DD'),
            };

            const initialServiceFilters = additionalServices.filter((_) => !!_);

            dispatch(updateServicesFilter(initialServiceFilters, true));

            if (returnCargoId) data.return_cargo_id = returnCargoId;
            return http.post('/api/v1/calculation/start/with_return/', data).then(
              (response: any) => {
                dispatch(setCalculationRunning(true));
                dispatch(setCalculationTaskId(response.singleTaskId));
                dispatch(setCalculationPollingTimer(setTimeout(() => {
                  dispatch(calculationResultPolling(response.singleTaskId));
                }, 5000)));
                return Promise.resolve(response);
              },
              (reject: any) => Promise.reject(reject),
            );
          },
        );
      },
    );
  }
  return Promise.reject(null);
};

export const startCalculation = (): ThunkAction => (dispatch, getState, http) => {
  const { senderCast, receiverCast } = getState().calculation;
  const { additionalServices } = getState().cargo;
  if (additionalServices.find((_) => _ === AdditionalServiceCode.RETURN)) return dispatch(startCalculationWithReturn());
  dispatch(setCalculationResults([]));
  dispatch(setCalculationDone(false));
  if (senderCast && receiverCast) {
    return dispatch(createCargo()).then(
      (cargoResponse) => {
        const sidePromises = [];
        if (!senderCast.addressCastId) {
          // @ts-ignore
          sidePromises.push(dispatch(createAddressCast(senderCast)).then(
            (response: any) => {
              dispatch(updateCalculationSenderCast({ addressCastId: response.addressCastId }));
              return Promise.resolve(response.addressCastId);
            },
          ));
        } else {
          sidePromises.push(Promise.resolve(senderCast.addressCastId));
        }
        if (!receiverCast.addressCastId) {
          // @ts-ignore
          sidePromises.push(dispatch(createAddressCast(receiverCast)).then(
            (response: any) => {
              dispatch(updateCalculationReceiverCast({ addressCastId: response.addressCastId }));
              return Promise.resolve(response.addressCastId);
            },
          ));
        } else {
          sidePromises.push(Promise.resolve(receiverCast.addressCastId));
        }
        return Promise.all(sidePromises).then(
          ([senderAddressCastId, receiverAddressCastId]) => {
            const data: anyObject = {
              sender_address_id: senderAddressCastId,
              receiver_address_id: receiverAddressCastId,
              cargo_id: cargoResponse.cargoId,
              pickup_date: moment().format('YYYY-MM-DD'),
            };

            const initialServiceFilters = additionalServices.filter((_) => !!_);

            dispatch(updateServicesFilter(initialServiceFilters, true));

            return http.post('/api/v1/calculation/start/', data).then(
              (response: any) => {
                dispatch(setCalculationRunning(true));
                dispatch(setCalculationTaskId(response.singleTaskId));
                dispatch(setCalculationPollingTimer(setTimeout(() => {
                  dispatch(calculationResultPolling(response.singleTaskId));
                }, 5000)));
                return Promise.resolve(response);
              },
              (reject: any) => Promise.reject(reject),
            );
          },
        );
      },
    );
  }
  return Promise.reject(null);
};

type CreateClaimResponse = {
  claimNumber: string,
};

export const createClaim = (data: anyObject): ThunkAction<Promise<CreateClaimResponse>> => async (dispatch, getState, http) => {
  await dispatch(updateCurrentCargo());
  return http.post(
    '/api/v1/shipping/claim/create/',
    { ...data, amo_id: getState().calculation.amoId },
  );
};

export const createSimpleTaskFromCast = (waybill: Waybill): ThunkAction => (dispatch, getState, http) => {
  if (waybill.deliveryType === WaybillDeliveryType.D2D) {
    return http.post('/api/v1/calculation/from_cast/start/', {
      sender_contact_cast_id: waybill.senderContactCastId,
      receiver_contact_cast_id: waybill.receiverContactCastId,
      cargo_cast_id: waybill.cargoCastId,
    }).then(
      ({
        sender, receiver, cargo: { cargoId, packages }, singleTaskId,
      }: anyObject) => {
        dispatch(setCalculationSenderCast(sender));
        dispatch(setCalculationReceiverCast(receiver));
        dispatch(setCargoId(cargoId));
        dispatch(setPackages(packages));
        dispatch(setCalculationRunning(true));
        dispatch(setCalculationTaskId(singleTaskId));
        dispatch(setCalculationPollingTimer(setTimeout(() => {
          dispatch(calculationResultPolling(singleTaskId));
        }, 5000)));
        return Promise.resolve(singleTaskId);
      },
    );
  }
  return Promise.reject();
};

export const fastCalc = (data: anyObject): ThunkAction => (dispatch, getState, http) => {
  const promises = [
    dispatch(createFastAddressCast(data.sender)),
    dispatch(createFastAddressCast(data.receiver)),
    dispatch(createFastCargo(data.cargo)),
  ];

  return Promise.all(executeAll(promises)).then(
    ([sender, receiver, cargo]) => {
      const errors: anyObject = {};
      const result: anyObject = {};

      if (sender.status === 'rejected') errors.sender = sender.errors;
      else result.sender = sender.response;

      if (receiver.status === 'rejected') errors.receiver = receiver.errors;
      else result.receiver = receiver.response;

      if (cargo.status === 'rejected') errors.cargo = cargo.errors;
      else result.cargo = cargo.response;

      if (Object.keys(errors).length) return Promise.reject(errors);
      dispatch(setCalculationResults([]));
      dispatch(setCalculationDone(false));
      const calcData: anyObject = {
        sender_address_id: result.sender.addressCastId,
        receiver_address_id: result.receiver.addressCastId,
        cargo_id: result.cargo.cargoId,
        pickup_date: moment().format('YYYY-MM-DD'),
      };

      const { additionalServices } = getState().cargo;
      const initialServiceFilters = additionalServices.filter((_) => !!_);

      dispatch(updateServicesFilter(initialServiceFilters, true));

      return http.post('/api/v1/calculation/start/', calcData).then(
        (response: any) => {
          dispatch(setCalculationRunning(true));
          dispatch(setCalculationTaskId(response.singleTaskId));
          dispatch(setCalculationPollingTimer(setTimeout(() => {
            dispatch(calculationResultPolling(response.singleTaskId));
          }, 5000)));
          return Promise.resolve(response);
        },
        (reject: any) => Promise.reject(reject),
      );
    },
  );
};

const createFastContactFromData = (data: anyObject): ThunkAction => (dispatch, getState, http) => {
  const cast: Partial<ContactCast> = {};

  const promises: Array<Promise<any>> = [];

  if (data.name) cast.contactName = data.name;
  if (data.phone) {
    cast.phones = [{
      number: data.phone.length === 10 ? `7${data.phone}` : data.phone,
    }];
  }
  if (data.email) {
    cast.email = data.email;
  }
  if (data.company) {
    promises.push(
      dispatch(datadaInnAutocomplete(data.company)).then(
        ({ suggestions }) => {
          if (suggestions.length) {
            const _ = suggestions[0];
            let title = _.value;

            if (_.data.name?.short) {
              title = _.data.name.short;

              if (_.data.opf?.short) {
                title += `, ${_.data.opf.short}`;
              }
            }
            cast.companyName = title;
            cast.inn = _.data.inn;
            cast.kpp = _.data.kpp;
            cast.ogrn = _.data.ogrn;
          }
          return Promise.resolve();
        },
      ),
    );
  }

  if (data.address) {
    promises.push(
      dispatch(geoSuggest(data.address)).then(
        async ({ suggestions }) => {
          if (suggestions.length) {
            const _ = suggestions[0];
            if (_.iso) cast.iso = _.iso;
            if (_.postcode) cast.postcode = _.postcode;
            else {
              cast.postcode = (await dispatch(getPostalCodeFromCoords(_.latitude as number, _.longitude as number))).postalCode;
            }
            if (_.region) cast.region = _.region;
            if (_.district) cast.district = _.district;
            if (_.city) cast.city = _.city;
            if (_.street) cast.street = _.street;
            if (_.house) cast.house = _.house;
            if (_.longitude && _.latitude) {
              cast.longitude = _.longitude;
              cast.latitude = _.latitude;
            } else {
              console.debug('ymaps', ymaps, ymaps?.api?.geocode);
              await ymaps.promise.then(
                () => {
                  console.debug('PROMISE!!', ymaps, ymaps.api, ymaps.api.geocode);
                  return ymaps.api.geocode(_.value, { kind: 'locality' }).then(
                    (results: any) => {
                      console.debug('results', results);
                      const arr = results.geoObjects.toArray();
                      if (arr.length) {
                        const [lat, lng] = arr[0].geometry.getCoordinates();
                        cast.longitude = lng;
                        cast.latitude = lat;
                      }
                      return Promise.resolve();
                    },
                  );
                },
              );
            }
          }
        },
      ),
    );
  }

  return Promise.all(executeAll(promises)).then(() => Promise.resolve(cast));
};

export const startFastCalc = (params: anyObject): ThunkAction<Promise<StartFastCalcResponse>> => (dispatch, getState, http) => {
  const promises: Array<Promise<any>> = [];

  if (params.amoId) dispatch(setAmoId(params.amoId));
  if (params.shareEmail) dispatch(setShareEmail(params.shareEmail));

  if (params.senderAddress) promises.push(dispatch(getFullContactInfoDetail(params.senderAddress)));
  else promises.push(Promise.reject());

  let receiverData: anyObject | undefined;
  if (params.receiverData) {
    try {
      receiverData = JSON.parse(params.receiverData);
    } catch (e) {
      console.error('Failed to parse');
      console.error(params.receiverData);
      console.error(e);
    }
  }

  if (receiverData) {
    promises.push(dispatch(createFastContactFromData(receiverData)));
  } else promises.push(Promise.reject());

  return Promise.all(executeAll(promises)).then(
    (results) => {
      const resp: Partial<StartFastCalcResponse> = {};
      if (results[0].status === 'rejected') {
        resp.initialState = FastCalcInitialState.SENDER_ADDRESS;
      } else {
        dispatch(contactCastComplexCreate(adaptToApi({ ...results[0].response, side: 'sender' }))).then(
          ({ addressCastId, contactCastId }) => {
            dispatch(setCalculationSenderCast({
              ...(results[0] as ResolvedPromise).response,
              addressCastId,
              contactCastId,
            }));
          },
        );
      }

      if (results[1].status === 'rejected') {
        if (!resp.initialState) {
          resp.initialState = FastCalcInitialState.RECEIVER_ADDRESS;
          resp.errors = results[1].errors as anyObject;
        }
      } else if (!resp.initialState) {
        const receiver = results[1].response;
        return Promise.resolve({ initialState: FastCalcInitialState.RECEIVER_ADDRESS, initialData: receiver });
        // return dispatch(contactCastComplexCreate(adaptToApi({
        //   ...receiver,
        //   side: 'receiver',
        // }))).then(
        //   ({ addressCastId, contactCastId }) => {
        //     dispatch(setCalculationSenderCast({
        //       ...receiver,
        //       addressCastId,
        //       contactCastId,
        //     }));
        //
        //   },
        //   (errors: anyObject) => Promise.resolve({ initialState: FastCalcInitialState.RECEIVER_ADDRESS, errors, initialData: receiver }),
        // );
      }

      return Promise.resolve(resp as StartFastCalcResponse);
    },
  );
};

export const getRateResult = (rateResultId: string): ThunkAction<Promise<SingleRate>> => (dispatch, getState, http) => http.get(
  `/api/v1/calculation/${rateResultId}/result/detail/`,
);

export const getBetterRate = (rateResultId: string): ThunkAction<Promise<SingleRate>> => (dispatch, getState, http) => http.get(
  `/api/v1/calculation/${rateResultId}/result/better/`,
);
